import * as _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { converToNumberFormat } from '../../../sheard/functions/numeral';
import NoData from '../NoData';
import { ISocialMediaTopPostEngagement, ITopPostEngagement } from '../ReportTrend';
import websiteImg from './../../../assets/images/social-media/blog-news.png';
import facebookImg from './../../../assets/images/social-media/facebook.png';
import instagramImg from './../../../assets/images/social-media/instagram.png';
import tiktokImg from './../../../assets/images/social-media/tiktok.png';
import webboardImg from './../../../assets/images/social-media/webboard.png';
import xImg from './../../../assets/images/social-media/x-twitter.png';
import youtubeImg from './../../../assets/images/social-media/youtube.png';

const TopPostEngagement = (props: {data: ISocialMediaTopPostEngagement}) => {
  const socialMedias = [
    {image: facebookImg, label: 'Facebook', class: 'fb', channel_type_id: 'facebook'},
    {image: instagramImg, label: 'Instagram', class: 'ig', channel_type_id: 'instagram'},
    {image: tiktokImg, label: 'Tiktok', class: 'tiktok', channel_type_id: 'tiktok'},
    {image: xImg, label: 'X', class: 'tw', channel_type_id: 'twitter'},
    {image: webboardImg, label: 'Webboard', class: 'yt', channel_type_id: 'webboard'},
    {image: youtubeImg, label: 'Youtube', class: 'yt', channel_type_id: 'youtube'},
    {image: websiteImg, label: 'News & Blog', class: 'ws', channel_type_id: 'website'},
  ];
  const [selectedSocalMedia, setSelectedSocialMedia] = useState<string>('all');
  const [topEngagements, setTopEngagment] = useState<ITopPostEngagement[]>([]);
  const [isNoData, setIsNoData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedSocalMedia in props.data) {
      const data: any = props.data;
      const _topEngagements = data[selectedSocalMedia];
      setTopEngagment(_topEngagements);
    }
  }, [selectedSocalMedia]);

  useEffect(() => {
    setIsLoading(true);
    const _isNoData = (topEngagements || []).length === 0;
    setIsNoData(_isNoData);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [topEngagements]);

  const mapSocialMediaClassSelected = (socialMedia: any) => {
    return selectedSocalMedia === socialMedia.channel_type_id ? `selected ${socialMedia.class}` : socialMedia.class;
  };

  const toggleFilter = (socialMediaValue: string) => {
      setSelectedSocialMedia(socialMediaValue);
  };

  const socialMediaImage = (channelTypeId: string) => {
    const socialMedia = _.find(socialMedias, ['channel_type_id', channelTypeId]);
    if (socialMedia) {
      return socialMedia.image;
    }
    return '';
  };

  const linkTo = (url: string) => {
    return window.open(url, '_blank');
  };

  return (
    <section style={{minHeight: '550px'}}>
      <div className='title'>
        <h3>Top posts by engagement</h3>
      </div>
      <ul className='select-type'>
        <li
        style={{justifyContent: 'center', display: 'flex', minWidth: '30px'}}
        className={selectedSocalMedia === 'all' ? 'selected select-all' : 'select-all'}
        onClick={() => toggleFilter('all')}>
          {/* <a className='tooltip' data-alt='Select All'>All<span>180</span></a> */}
          <a style={{padding: '4px 12px'}} className='tooltip' data-alt='Select All'>All</a>
        </li>
        {socialMedias.map((socialMedia) => {
          return (
            <li
            style={{justifyContent: 'center', display: 'flex', minWidth: '30px'}}
            className={`${mapSocialMediaClassSelected(socialMedia)}`}
            onClick={() => toggleFilter(socialMedia.channel_type_id)}
            >
            <a style={{padding: '4px 12px'}} className='tooltip' data-alt={socialMedia.label}>
              <img src={socialMedia.image} alt='' />
              {/* <span>20</span> */}
            </a>
          </li>
          );
        })
        }
      </ul>
      {isNoData ? <div style={{height: '550px'}}><NoData /></div> : <ul className={isLoading ? `post-list loading` : `post-list`}>
      <ReactTooltip place='bottom' type='dark' effect='float'  />
        {topEngagements.map((topEngagement: ITopPostEngagement, i: number) => {
          return (
            <li>
            <h3 style={{width: '10px'}}>{i + 1}</h3><img src={topEngagement.from.avatar_url} alt='' />
            <article>
              <div className='avatar-detail'>
                <div className='name' data-tip={topEngagement.from.name}>
                  <img src={socialMediaImage(topEngagement.channel_type_id)} alt='' />
                  <p>{topEngagement.from.name}</p>
                </div>
                <div className='time'>
                  {/* <p>10/10/24 7:51 am</p> */}
                  <p>{moment(topEngagement.created_time * 1000).format('DD/MM/YY hh:mm')}</p>
                </div>
              </div>
              <div style={{cursor: 'pointer'}} className='post-detail' onClick={() => linkTo(topEngagement.link)}>
                {/* <div className='img'><img src='../../images/temps/temp_1.jpg' alt='' /></div> */}
                <div className='post'>
                    <p>{topEngagement.message}</p>
                </div>
              </div>
                <ul className='engagement'>
                  <li data-tip={`like ${converToNumberFormat(topEngagement.likes_count)}`}><i className='fa fa-thumbs-up'></i>
                    <p>{converToNumberFormat(topEngagement.likes_count)}</p>
                  </li>
                  <li data-tip={`comment ${converToNumberFormat(topEngagement.comment_count)}`}><i className='fa fa-comment'></i>
                    <p>{converToNumberFormat(topEngagement.comment_count)}</p>
                  </li>
                  <li data-tip={`share ${converToNumberFormat(topEngagement.share_count)}`}><i className='fa fa-share'></i>
                    <p>{converToNumberFormat(topEngagement.share_count)}</p>
                  </li>
                </ul>
            </article>
          </li>
          );
        })}
      </ul>
      }
    </section>
  );
};

export default TopPostEngagement;
