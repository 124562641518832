import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { capitalizeFirstChar } from '../../../sheard/functions/capitalize-first-char';
import { colorCode } from '../../../sheard/functions/color-code';
import { converToNumberFormat } from '../../../sheard/functions/numeral';
import BarLineChart from '../Echarts/BarLineChart';
import NoData from '../NoData';
import { IReportTrendByDateAndSocialMedia } from '../ReportTrend';

const MessagesTrend = (props: { data: IReportTrendByDateAndSocialMedia[] }) => {
  const { data } = props;
  const [isNoData, setIsNoData] = useState(false);

  useEffect(() => {
    if (!data || data.length === 0) {
      setIsNoData(true);
    } else {
      setIsNoData(false);
    }
  }, [data]);

  return (
    <section>
      <div className='title'>
        <h3>Message Trend</h3>
      </div>
      <div className='chart-container' style={{ height: '250px' }}>
        {isNoData ? <NoData /> : <BarLineChart chartOption={getOption(data)} />}
      </div>
    </section>
  );
};

export default MessagesTrend;

const getOption = (data: IReportTrendByDateAndSocialMedia[] = []) => {
  const channelTypeValue: any = {};
  const xAxisDatas: any = [];
  data.forEach((d: IReportTrendByDateAndSocialMedia) => {
    xAxisDatas.push(
      moment(d.date_by_day)
        .format('DD MMM')
        .toUpperCase(),
    );
    const channelTypeCount: any = d.channel_type_count;
    for (const key in channelTypeCount) {
      if (channelTypeCount[key] >= 0 ) {
        const v = channelTypeCount[key] || 0;
        if (channelTypeValue[key]) {
          channelTypeValue[key].push(v);
        } else {
          channelTypeValue[key] = [v];
        }
      }
    }
  });

  const socialKeys = Object.keys(channelTypeValue);
  const graphDatas = socialKeys.map((socialKey: string) => {
    return {
      type: 'line',
      name: socialKey === 'website' ? 'News & Blog' : capitalizeFirstChar(socialKey),
      data: channelTypeValue[socialKey],
    };
  });
  const _colorCode: any = colorCode;

  return {
    format_tooltip: (series: any) => formatTooltip(series),
    xAxis_data: xAxisDatas,
    data: graphDatas,
    data_color: socialKeys.map((socialKey: string) => {
      return _colorCode[socialKey];
    }),
  };
};

const formatTooltip = (series: any) => {
  const lists = series.map((serie: any) => {
    return `<li><p>${serie.seriesName}</p><p><span>${converToNumberFormat(serie.value)}</span></p></li>`;
  });

  return `<div class='echarts-tooltip'>
            <p class='echarts-tooltip-title'>${series[0].name}</p>
            <ul>${lists.join('')}</ul>
          </div>`;
};
