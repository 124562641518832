import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getReportTrend, getTopics } from '../../sheard/services/ReportService';
import Category from '../Category/Category';
import BubbleChart from './Charts/BubbleChart';
import CategoryBarHeatChart from './Charts/CategoryBarHeatChart';
import CircularProgressChart from './Charts/CircularProgressChart';
import DemographicChart from './Charts/DemographicChart';
import MessagesTrend from './Charts/MessageTrend';
import ShareOfVoiceChart from './Charts/ShareOfVoiceChart';
import TopHashTagChart from './Charts/TopHashtagChart';
import TopPostEngagement from './Charts/TopPostEngagement';
import TopUser from './Charts/TopUser';
import DateTime from './DateTime';
import LoadingBox from './Loading';
import OverlayLoading from './OverlayLoading';

const ReportTrend = () => {
  const [data, setData] = useState<IReportTrend | null>(null);
  const [topics, setTopic] = useState<ITopic[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTopicLoading, setIsTopicLoading] = useState(false);
  const [selectTopic, setSelectTopic] = useState<string | null>(null);
  const [startTime, setStartTime] = useState<any>(moment().subtract(1, 'day').startOf('day').unix());
  const [endTime, setEndTime] = useState<any>(moment().subtract(1, 'day').endOf('day').unix());
  const [query, setQuery] = useState<any>(null);

  const channelTypeIds = [
    'facebook',
    'twitter',
    'instagram',
    'youtube',
    'webboard',
    'website',
    'tiktok',
  ];

  const openLoading = () => {
    setIsLoading(true);
  };

  const closeLoading = () => {
    setIsLoading(false);
  };

  const notifyError = (message: string) => {
    toast.error(message, {
      position: 'bottom-right',
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      transition: Bounce,
      });
  };

  useEffect(() => {
    setIsTopicLoading(true);
    getTopics()
      .then((res: any) => {
        setTopic(res.data);
        setIsTopicLoading(false);
      })
      .catch((error: any) => {
        notifyError(error.message);
      });
  }, []);

  useEffect(() => {
    if (!selectTopic) {
      return;
    }
    const params = {
      category_ids: selectTopic,
      channel_type_ids: channelTypeIds.join(','),
      start_time: startTime,
      end_time: endTime,
    };
    setQuery(params);
    openLoading();
    getReportTrend(params)
      .then((res: any) => {
        setData(res.data);
        closeLoading();
      })
      .catch((error: any) => {
        closeLoading();
        notifyError(error.message);
      });
  }, [selectTopic, startTime, endTime]);

  const onSelectTopic = (topic: ITopic) => {
    setSelectTopic(topic._id);
  };

  const handleDateChange = (start_date: any, end_date: any) => {
    setStartTime(start_date);
    setEndTime(end_date);
  };

  return (
    <div>{isTopicLoading ? <OverlayLoading />   : <div>
      <Category type={'topic'} topics={topics} onSelectTopic={onSelectTopic} />
      <main className='report-main'>
        <div className='report'>
          <DateTime handleDateChange={handleDateChange} />
          {isLoading || !data ? (
            <LoadingBox title='Message Trend' />
          ) : (
            <MessagesTrend data={data.trend_by_date_and_social_media} />
          )}
          {isLoading || !data ? (
            <LoadingBox />
          ) : (
            <CircularProgressChart data={data.trend_by_social_media} />
          )}
          {isLoading || !data ? (
            <LoadingBox title='Heat Map' />
          ) : (
            <BubbleChart data={data.trend_by_date} query={query} />
          )}
          {isLoading || !data ? (
            <LoadingBox />
          ) : (
            <CategoryBarHeatChart data={data.categories} />
          )}
          <div className='half'>
            {isLoading || !data ? (
              <LoadingBox title='Share of voices' />
            ) : (
              <ShareOfVoiceChart data={data.share_of_voice} />
            )}
            {isLoading || !data ? (
              <LoadingBox title='Top users' />
            ) : (
              <TopUser data={data.top_user} />
            )}
          </div>
          {isLoading || !data ? (
            <LoadingBox title='Top hashtag' />
          ) : (
            <TopHashTagChart data={data.top_hashtag} />
          )}
          {/* <DemographicChart /> */}
          {isLoading || !data ? (
            <LoadingBox title='Top posts by engagement' />
          ) : (
            <TopPostEngagement data={data.top_post_by_engagement} />
          )}
        </div>
      </main>
    </div>
    }
    <ToastContainer/>
    </div>
  );
};

export default ReportTrend;

interface IReportTrendByDateAndSocialMediaChannelTypeCount {
  facebook: number;
  twitter: number;
  instagram: number;
  youtube: number;
  webboard: number;
  website: number;
  tiktok: number;
}

export interface IReportTrendByDateAndSocialMedia {
  date_by_day: string;
  count: string;
  channel_type_count: IReportTrendByDateAndSocialMediaChannelTypeCount;
}

export interface IReportTrendBySocialMedia {
  facebook: number;
  twitter: number;
  instagram: number;
  youtube: number;
  webboard: number;
  website: number;
  tiktok: number;
}

interface IBubbleChartDatebyhour {
  hour: string;
  count: string;
}

export interface IBubbleChart {
  date_by_day: string;
  count: string;
  date_by_hour: IBubbleChartDatebyhour[];
}

export interface IShareOfVoice {
  name: string;
  count: string;
}

export interface ITopUser {
  _id: string;
  name: string;
  avatar_url: string;
  count: string;
  engagement: string;
  followers: string;
}

export interface ITopHashTag {
  name: string;
  count: string;
}

export interface ICategories {
  name: string;
  count: string;
}

export interface ITopic {
  _id: string;
  keywords: string[];
  name: string;
}
interface ITopPostEngagementFrom {
  id: string;
  name: string;
  avatar_url: string;
}
export interface ITopPostEngagement {
  id: string;
  message: string;
  channel_type_id: string;
  background_image: string;
  ev: number;
  likes_count: number;
  comment_count: number;
  share_count: number;
  from: ITopPostEngagementFrom;
  created_time: number;
  link: string;
}

export interface ISocialMediaTopPostEngagement {
  all: ITopPostEngagement[];
  facebook: ITopPostEngagement[];
  instagram: ITopPostEngagement[];
  tiktok: ITopPostEngagement[];
  twitter: ITopPostEngagement[];
  webboard: ITopPostEngagement[];
  website: ITopPostEngagement[];
  youtube: ITopPostEngagement[];
}
interface IReportTrend {
  trend_by_date_and_social_media: IReportTrendByDateAndSocialMedia[];
  trend_by_social_media: IReportTrendBySocialMedia;
  trend_by_date: IBubbleChart[];
  share_of_voice: IShareOfVoice[];
  top_user: ITopUser[];
  top_hashtag: ITopHashTag[];
  categories: ICategories[];
  top_post_by_engagement: ISocialMediaTopPostEngagement;
}
